import {configureStore} from "@reduxjs/toolkit";
import { apiSlice} from "./rtk-api/apiSlice";
import {createWrapper} from 'next-redux-wrapper';

const makeStore = (context) =>
	configureStore({
		reducer: {
			[apiSlice.reducerPath]: apiSlice.reducer,
		},
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				thunk: {
					extraArgument: {
						cookies: context.req?.cookies,
						headers: context.req?.headers
					},
				}
			})
				.concat(apiSlice.middleware)
	})

export const wrapper = createWrapper(makeStore, {debug: process.env.NODE_ENV === 'development'});
